import * as React from 'react';
import {Helmet} from 'react-helmet';
import {lowerCase} from 'lodash';

import Layout from '@components/layouts/layout';
import Navigation from '@components/navigation/navigation';
import Footer from '@components/footer/footer';

import Header from '@components/kola-naukowe/header';
import Filters from '@components/kola-naukowe/filters';
import Search from '@components/kola-naukowe/search';
import OrganisationsGrid from '@components/common/organisations-grid';

const KolaNaukowe = ({pageContext}) => {
  const [kns, setKns] = React.useState([]);
  const currentTags = React.useRef([]);
  const currentSearchString = React.useRef('');
  const currentAreas = React.useRef(null);

  React.useEffect(() => {
    setKns(pageContext.kns);
  }, []);

  const applyFiltersAndSearch = (tagsArg, searchArg, areasArg) => {
    let knsToBeSet = pageContext.kns.map(i => i);

    if (tagsArg.length > 0) knsToBeSet = knsToBeSet.filter(kn => kn.tags.some(tag => tagsArg.includes(tag)));

    if (searchArg !== '') knsToBeSet = knsToBeSet.filter(kn => lowerCase(kn.name).includes(lowerCase(searchArg)));

    if (areasArg) knsToBeSet = knsToBeSet.filter(kn => kn.area === areasArg);

    setKns(knsToBeSet);
  };

  const updateFiltersHandler = currentSelectedTags => {
    currentTags.current = currentSelectedTags;
    applyFiltersAndSearch(currentSelectedTags, currentSearchString.current, currentAreas.current);
  };

  const updateSearchHandler = searchString => {
    currentSearchString.current = searchString;
    applyFiltersAndSearch(currentTags.current, searchString, currentAreas.current);
  };

  const updateAreasHandler = currentSelectedAreas => {
    currentAreas.current = currentSelectedAreas;
    applyFiltersAndSearch(currentTags.current, currentSearchString.current, currentSelectedAreas);
  };

  return (
    <Layout>
      <Helmet>
        <title>Koła naukowe – Studenckie Koła Naukowe AGH</title>
        <meta property="og:title" content={`Koła naukowe – Studenckie Koła Naukowe AGH`} />
      </Helmet>

      <Navigation customPagesNav={pageContext.customPagesNav} />
      <Header />

      <Search updateSearchHandler={updateSearchHandler} />
      <Filters
        tags={pageContext.knsTags}
        updateFiltersHandler={updateFiltersHandler}
        updateAreasHandler={updateAreasHandler}
      />
      <OrganisationsGrid
        kns={kns}
        paginate={true}
        itemsPerPage={24}
        tags={currentTags.current}
        searchString={currentSearchString.current}
        areas={currentAreas.current}
      />
      <Footer />
    </Layout>
  );
};

export default KolaNaukowe;
